<template>
  <div>
    <div class="mapLeft" @click="mapLeftClick" v-if="leftBox">
      <p>公物仓资产分析</p>
      <div class="icon"><DoubleRightOutlined /></div>
    </div>
    <!-- 左弹窗内容 -->
    <div class="mapLeftPup" v-if="leftShow">
      <a-card
        title="公务仓资产分析"
        headStyle="background:#37a2da;color:#FFFFFF;"
        :bordered="false"
      >
        <template #extra>
          <div @click="mapLeftPupClick">
            <DoubleLeftOutlined style="color: #ffffff; cursor: pointer" />
          </div>
        </template>
        <a-input-search
          v-model:value="value"
          placeholder="搜索专题名称"
          enter-button
          @search="onSearch"
        />
        <div class="treeBox">
          <a-tree
            checkable
            defaultExpandAll
            placeholder="请选择所属单位"
            :tree-data="organizationList"
            :replaceFields="{
              children: 'children',
              title: 'name',
              key: 'index',
              value: 'index'
            }"
            :checkedKeys="checkedKey"
            @check="select"
          />
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons-vue'
// import * as organizationApi from '@/api/sys/organization'
export default {
  props: {
    warehouseList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    DoubleRightOutlined,
    DoubleLeftOutlined
  },
  data() {
    return {
      leftBox: true,
      leftShow: false,
      organizationList: [],
      checkedKey: [-1]
    }
  },
  watch: {
    warehouseList() {
      this.initData()
    }
  },
  mounted() {
    // this.getOrganizationList()
    this.$nextTick(() => {
      this.initData()
    })
  },
  created() {},
  methods: {
    initData() {
      const children = this.warehouseList
      var data = [
        {
          name: '厦门出入境边防检查总站',
          key: -1,
          index: -1,
          value: -1,
          children: children
        }
      ]
      this.organizationList = data
    },
    // 左内容按钮
    mapLeftClick() {
      this.leftBox = false
      this.leftShow = true
    },
    // 左弹窗按钮
    mapLeftPupClick() {
      this.leftBox = true
      this.leftShow = false
    },
    // 资产概况的数量金额选择（左上）
    radioChange(e) {
      console.log(e.target.value)
      if (e.target.value === 'number') {
        this.$refs.home.change(1)
      } else {
        this.$refs.home.change(2)
      }
    },
    // getOrganizationList() {
    //   organizationApi.all({ organizationType: 'unit' }).then((res) => {
    //     if (res.code === 0) {
    //       this.organizationList = res.data
    //     } else {
    //       this.$message.error(res.data.msg)
    //     }
    //   })
    // }
    // 树结构选择时触发更新数据
    select(value) {
      this.checkedKey = value
      const data = this.warehouseList
      const valueString = ',' + value.join(',') + ','
      data.forEach((item2) => {
        if (valueString.indexOf(',' + item2.index + ',') !== -1) {
          item2.show = true
        } else {
          item2.show = false
        }
      })
      this.$emit('update:warehouseList', data)
    }
  }
}
</script>

<style lang="less" scoped>
.mapLeft {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 20px;
  color: #ffffff;
  background: #37a2da;
  writing-mode: vertical-lr;
  line-height: 12px;
  display: flex; /*实现垂直居中*/
  align-items: center; /*实现水平居中*/
  letter-spacing: 4px;
  justify-content: center;
  padding: 20px;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
.mapLeftPup {
  position: absolute;
  top: 50px;
  left: 40px;
  .treeBox {
    width: 100%;
    height: 641px;
    padding: 10px 0;
    overflow: scroll;
  }
  :deep(.ant-card) {
    margin-bottom: 0;
  }
  :deep(.ant-card-extra),
  :deep(.ant-card-head-title) {
    padding: 9px 0;
  }
  :deep(.ant-card-body) {
    padding: 10px;
  }
}
</style>
